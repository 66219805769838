.infoBoxShader{
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
}

.infoBoxWrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 3;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 10px black;
}

.infoBoxWrapperShow{
    display: inline-block;
    opacity: 0;
    animation: showAnimation .5s linear forwards;
}

.infoBoxContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infoBoxTextWrapper{
    width: 70%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.infoBoxText{
    color: white;
    text-align: center;
    font-size: 18px;
}

.InfoBox-Button-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.infoBoxConfirm{
    width: 150px;
    height: 50px;
    border: none;
    border-radius: 15px;
    background: rgb(12, 12, 12);
    color: white;
    transition-duration: 1s;
    font-size: 20px;
}

.infoBoxConfirm:hover{
    background: rgb(49, 49, 49);
}

.popoutVideo{
    width: 560px;
    height: 315px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@-webkit-keyframes showAnimation {
    0%  {
      opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }
  
  @-moz-keyframes showAnimation {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }
  
  @-ms-keyframes showAnimation {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }
  
  @-o-keyframes showAnimation {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }
  
  @keyframes showAnimation {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }

  @media(max-width: 890px){

    .infoBoxWrapper{
        width: 100%;
    }
   
    .infoBoxTextWrapper{
        width: 90%;
    }
    

    .infoBoxText{
        font-size: 15px;
    }
  }

  @media(max-width: 615px){

    .popoutVideo{
        width: 400px;
        height: 200px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

  }

  @media(max-width: 425px){

    .infoBoxWrapper{
        border-radius: 0;
    }

    .infoBoxWrapper{
        width: 100%;
        height: 100%;
    }
   
    .infoBoxContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .popoutVideo{
        width: 300px;
        height: 200px;
    }

  }

  @media(max-width: 400px){

    .infoBoxText{
        font-size: 10px;
    }

  }

  @media(max-width: 300px){

    .infoBoxText{
        font-size: 12px;
    }

  }