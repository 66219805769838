html, body{
  /*background: linear-gradient(#990000, #003366);*/
  font-family:"Andalé Mono, monospace" !important; 
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-image: url("./assets/stars.gif");
  /*background: black; linear-gradient(to right, rgb(170, 170, 170), rgb(131, 131, 131), rgb(90, 90, 90));*/
}

/*START HERE NEXT TIME WIERD FORMATTING ISSUE WITH HOME PAGE*/

.weAreGoingContainer{
  
}

.weAreGoingPopout{
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-size: 50px;
  margin: 0;
  cursor: pointer;
  transition-duration: 1s;
  z-index: 2;
}

.weAreGoingPopout:hover{
  color: #f1ff6f;
}

.weAreGoingWrapper{
  position: absolute;
  top: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


body::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5;
} 

body::-webkit-scrollbar-thumb{
  background-color: black;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.WeAreGoing-Title-Wrapper{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.2);
  /*box-shadow: 2px 2px 10px black;*/
  z-index: 1;
}

.WeAreGoing-Title{
  /*font-family: Arial, sans-serif;*/
  font-family:"Frutiger LT W01 45 Light" !important;
  /*color: white;*/
  width: 100%;
  text-align: center;
  opacity: 0;
  animation: showTitleAnimation 2s linear forwards;
  animation-delay: 1s;
  margin: 0;
}

.WAG-We{
  font-size: 100px;
  margin-right: 20px;
  animation: changeTitleColor 5s linear infinite;
}

.WAG-Are{
  font-size: 100px;
  margin-right: 20px;
  animation: changeTitleColor 5s linear infinite;
}

.WAG-Going{
  font-size: 100px;
  animation: changeTitleColor 5s linear infinite;
}

.WeAreGoing-Reciever-Wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(17, 17, 17);
  height: 200px;
}

.WeAreGoing-Reciever-Title{
  margin: 0;
}

.WeAreGoing-Donation-Wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(29, 29, 29);
  height: 400px;
}

.WeAreGoing-Donation-ScrollWrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
}

.WeAreGoing-Video-Wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 400px;
}

.WeAreGoing-Paypal-Wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.WeAreGoing-Paypal-Title{
  text-align: center;
  animation: reverseAnimation 1s linear forwards;
  animation-delay: 1s;
  opacity: 0;
  cursor: default;
  z-index: 2;
  color: rgb(231, 231, 231);
  /*transition-duration: 4s;*/
}

.WeAreGoing-Paypal-Title-Wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.WAG-CoverFee-Wrapper{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.WAG-CoverFee-Label{
  color: white;
  margin-right: 10px;
  font-size: 20px;
  transition-duration: 0.5s;
  cursor: pointer;
}

.WAG-CoverFee-Label:hover{
  color: #f1ff6f;
}

.WAG-CoverFee-Checkbox{
  transform: scale(1.5);
}

/*
.WelsDollar-Paypal-Title-Wrapper:hover #test{
  color: #383838;
}*/

#test{
  transition-duration: .5s;
}

.WeAreGoing-Title{
  color: white;
  font-size: 40px;
}

.WeAreGoing-Reciever-Title{
  color: rgb(231, 231, 231);
  font-size: 20px;
  margin-bottom: 10px;
}

.WeAreGoing-Reciever{
  color: rgb(231, 231, 231);
  font-size: 40px;
  margin: 0;
  text-align: center;
  transition-duration: 0.5s;
}

.WeAreGoing-Reciever:hover{
  color: #f1ff6f
}

.WeAreGoing-Link{
  text-decoration: none;
}

.WeAreGoing-Reciever-Divider{
  width: 100px;
  height: 2px;
  background-color: rgb(179, 179, 179);
  z-index: 2;
  margin-bottom: 10px;
}

.WeAreGoing-Donation-Title{
  color: rgb(231, 231, 231);
  font-size: 30px;
  margin: 10px;
}

#donationDivider{
  margin-bottom: 20px;
}

.WeAreGoing-VideoFrame{
  width: 580px; 
  height: 335px;
  /*box-shadow: 2px 2px 10px black;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.WeAreGoing-Video{
  width: 560px; 
  height: 315px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px black;
  margin-top: 10px;
}

.TextShow{
  opacity: 0;
  animation: showAnimation 1s linear forwards;
}

.WeAreGoing-SubscribeMessage-Wrapper{
  width: 90%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.WeAreGoing-SubscribeMessage{
  text-align: center;
  color: white;
}

.WeAreGoing-SubscribeForm{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.WeAreGoing-SubscribeInput{
  width: auto;
  height: 100%;
  box-shadow: 2px 2px 10px black;
  margin-right: 10px;
  border: none;
  font-size: 20px;
  border-radius: 15px;
  padding: 5px;
}

.WeAreGoing-SubscribeButton{
  width: auto;
  height: 100%;
  background: rgb(43, 43, 43);
  border: none;
  box-shadow: 2px 2px 10px black;
  font-size: 20px;
  color: white;
  border-radius: 15px;
  transition-duration: .5s;
}

.WeAreGoing-SubscribeButton:hover{
  background: rgb(170, 170, 170);
  color: rgb(0, 0, 0);
}

@media(max-width: 850px){
  .WeAreGoing-Paypal-Wrapper-Given{
    height: 300px;
  }
}

@media(max-width: 770px){
  .WeAreGoing-Title{
    display: flex;
    flex-direction: column;
  }

  .WAG-We{
    margin-right: 0px;
  }

  .WAG-Are{
    margin-right: 0px;
  }

  .WAG-Going{
    margin-right: 0px;
  }
}

@media(max-width: 670px){
  #hiddenPaypalText{
    font-size: 17px;
  }
}

@media(max-width: 650px){
  .WeAreGoing-VideoFrame{
    width: 460px; 
    height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .WeAreGoing-Video{
    width: 460px; 
    height: 215px;
  }

  .weAreGoingPopout{
    top: 25px;
  }
}

@media(max-width: 490px){
  .WeAreGoing-VideoFrame{
    width: 90%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .WeAreGoing-Video{
    width: auto; 
    height: 100%;
  }

  .WeAreGoing-Paypal-Title{
    font-size: 20px;
  }

  .WeAreGoing-Title-Wrapper{
    align-items: flex-start;
  }

  .WeAreGoing-Title{
    font-size: 40px;
  }
}

@media(max-width: 450px){
  #hiddenPaypalText{
    font-size: 15px;
  }
}

@media(max-width: 425px){
  .WeAreGoing-SubscribeForm{
    flex-direction: column;
    height: auto;
  }

  .WeAreGoing-SubscribeInput{
    margin-right: 0px;
    height: 40px;
  }
  
  .WeAreGoing-SubscribeButton{
    margin-top: 20px;
    height: 40px;
  }
}

@media(max-width: 425px){
  .WAG-We{
    font-size: 80px;
  }

  .WAG-Are{
    font-size: 80px;
  }

  .WAG-Going{
    font-size: 80px;
  }
}

@media(max-width: 375px){
  .WeAreGoing-Paypal-Wrapper-Given{
    height: 400px;
  }

  .weAreGoingPopout{
    right: 20px;
  }
}

@media(max-width: 340px){
  .WeAreGoing-Paypal-Wrapper-Given{
    height: 400px;
  }
}

@media(max-width: 330px){
  .WAG-We{
    margin-right: 0;
  }
}

@media(max-width: 300px){
  .WeAreGoing-Title{
    font-size: 30px;
  }
  
  .WeAreGoing-Reciever-Title{
    font-size: 20px;
  }
  
  .WeAreGoing-Reciever{
    font-size: 15px;
  }
  
  .WeAreGoing-Donation-Title{
    font-size: 20px;
  }

  .WeAreGoing-Paypal-Wrapper-Given{
    height: 450px;
  }
}

.WAG-Title:nth-child(1) {animation-delay: 1s; }
.WAG-Title:nth-child(2) {animation-delay: 2s; }
.WAG-Title:nth-child(3) {animation-delay: 3s; }

/*Reverse Animation*/

@-webkit-keyframes showAnimation {

  0%  { 
    opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }

@-moz-keyframes showAnimation {
  0%  { 
    opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }

@-ms-keyframes showAnimation {
  0%  { 
    opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }

@-o-keyframes showAnimation {
  0%  { 
    opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }

@keyframes showAnimation {
  0%  { 
    opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }

  @-webkit-keyframes showTitleAnimation {

    0%  { 
      opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
    }
  
  @-moz-keyframes showTitleAnimation {
    0%  { 
      opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
    }
  
  @-ms-keyframes showTitleAnimation {
    0%  { 
      opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
    }
  
  @-o-keyframes showTitleAnimation {
    0%  { 
      opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
    }
  
  @keyframes showTitleAnimation {
    0%  { 
      opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
    }

    @-webkit-keyframes changeTitleColor {

      0%  { 
        color: #ffffff; }
      20% {
        color: #c2cbff;}
      40% {
        color: #99a8ff;}
      60% {
        color: #6b81ff;}
      80% {
        color: #425eff;}
      100% {
        color: #2142ff;}
      }
    
    
      @-moz-keyframes changeTitleColor {

        0%  { 
          color: #ffffff; }
        20% {
          color: #c2cbff;}
        40% {
          color: #99a8ff;}
        60% {
          color: #6b81ff;}
        80% {
          color: #425eff;}
        100% {
          color: #2142ff;}
        }
    
        @-ms-keyframes changeTitleColor {

          0%  { 
            color: #ffffff; }
          20% {
            color: #c2cbff;}
          40% {
            color: #99a8ff;}
          60% {
            color: #6b81ff;}
          80% {
            color: #425eff;}
          100% {
            color: #2142ff;}
          }
    
          @-o-keyframes changeTitleColor {

            0%  { 
              color: #ffffff; }
            20% {
              color: #fcffe3;}
            40% {
              color: #f9ffc7;}
            60% {
              color: #f7ffb0;}
            80% {
              color: #f4ff91;}
            100% {
              color: #f2ff7a;}
            }
            @keyframes changeTitleColor {

              0%  { 
                color: #f1ff6f; }
              20% {
                color: #ffffff;}
              40% {
                color: #ffffff;}
              60% {
                color: #ffffff;}
              80% {
                color: #ffffff;}
              100% {
                color: #ffffff;}
              }