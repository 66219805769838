.confirmDonation{
    display: none;
    position: fixed;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3; /* Sit on top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
}

.confirmDonationContent{
    background-color: rgba(0, 0, 0, 0.9);
    margin: auto;
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 10px black;
    border-radius: 20px;
}

.confirmDonationContentShow{
    opacity: 0;
    animation: showConfirm .5s linear forwards;
}

/*.modalClose{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}*/

.confirmDonationClose:hover,
.confirmDonationClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.ConfirmDonation-Button-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.confirmDonationText{
    font-size: 20px;
    text-align: center;
    color: white;
}

.confirmDonationConfirmText{
    width: 120px;
    height: 50px;
    margin-right: 10px;
    border: none;
    background: #0a0a0a;
    transition-duration: .5s;
    border-radius: 15px;
    color: white;
    font-size: 20px;
}

.confirmDonationConfirmText:hover{
    background: #303030;
}

@-webkit-keyframes showConfirm {
    0%  {
      opacity: 0; }
  20% {
    opacity: .2;}
  40% {
    opacity: .4;}
  60% {
    opacity: .6;}
  80% {
    opacity: .8;}
  100% {
    opacity: 1;}
  }
  
  @-moz-keyframes showConfirm {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }
  
  @-ms-keyframes showConfirm {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }
  
  @-o-keyframes showConfirm {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }
  
  @keyframes showConfirm {
    0%  {
        opacity: 0; }
    20% {
      opacity: .2;}
    40% {
      opacity: .4;}
    60% {
      opacity: .6;}
    80% {
      opacity: .8;}
    100% {
      opacity: 1;}
  }

@media(max-width: 650px){
    .confirmDonationContent{
        width: 80%;
    }
}