.Scroll-Picker-Container{
    height: 80px;
    width: 300px;
}

.Scroll-Picker-Wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: black;
    box-shadow: 2px 2px 10px black;
}

.WeAreGoing-Spinner-DollarSignWrapper{
    width: 37.5px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(29, 29, 29);
}

.WeAreGoing-Spinner-DollarSign{
    color: white;
    text-align: center;
}

.Picker-SpinnerRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: hidden;
}

.Picker-SpinnerWrapper{
    background-color: rgb(29, 29, 29);
    width: 37.5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    transition-duration: 1s;
}

.Picker-ItemWrapper{
    width: 37.5px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-color: rgb(39, 39, 39);
    border-style: solid;
    border-width: 1px;
}

.Picker-Item{
    color: white;
}   
